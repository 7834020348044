import { generatePageUrl } from "./utils";

export const BASE_URL = "/";
export const PRODUCTS_PER_PAGE = 8;
export const SUPPORT_PHONE_NUMBER = "026197409690";
export const SUPPORT_EMAIL = "info@schungit-original.de";
export const PASSWORD_RESET_URL = "https://schungit-original.de/password-reset";
export const PROVIDERS = {
  BRAINTREE: "Braintree",
  DUMMY: "Dummy",
  IN_ADVANCE: "Payment in advance",
  PAYPAL: "PayPal",
  STRIPE: "Stripe",
};
export const STATIC_PAGES = [
  {
    label: "Über Mich",
    url: generatePageUrl("schungit-original-shop"),
  },
  {
    label: "Impressum/Datenschutz",
    url: generatePageUrl("impressum"),
  },
  {
    label: "Versand & Zahlung",
    url: generatePageUrl("2012-12-05-11-05-38"),
  },
  {
    label: "10 Gründe zu kaufen",
    url: generatePageUrl("10-gruende-schungit-zu-kaufen"),
  },
  {
    label: "Wiederverkäufer",
    url: generatePageUrl("schungit-fuer-wiederverkaeufer"),
  },
  {
    label: "Schungit - Schutz gegen Elektrosmog",
    url: generatePageUrl("artikel-1"),
  },
  {
    label: "Zylinder, Stäbe, Massage Kugeln",
    url: generatePageUrl("artikel-2"),
  },
  {
    label: "Artikel 3",
    url: generatePageUrl("artikel-3"),
  },
  {
    label: "Artikel 4",
    url: generatePageUrl("artikel-4"),
  },
];
export const HOME_STATIC_PAGES = [
  {
    id: "5",
    image: require("../images/home-banner-1.png"),
  },
  {
    id: "6",
    image: require("../images/home-banner-2.png"),
  },
  {
    id: "4",
    image: require("../images/home-banner-3.png"),
  },
];
export const SOCIAL_MEDIA = [
  {
    ariaLabel: "facebook",
    href: "http://de-de.facebook.com/pages/schungit-original/167503410006664?hc_location=timeline",
    path: require("../images/facebook-icon.svg"),
  },
  {
    ariaLabel: "instagram",
    href: "https://www.instagram.com/schungit_original.de/",
    path: require("../images/instagram-icon.svg"),
  },
  /*{
    ariaLabel: "twitter",
    href: "https://twitter.com/getsaleor",
    path: require("../images/twitter-icon.svg"),
  },*/
  {
    ariaLabel: "youtube",
    href: "https://www.youtube.com/watch?v=8lYEF_J2nxE",
    path: require("../images/youtube-icon.svg"),
  },
];
export const META_DEFAULTS = {
  custom: [],
  description: "",

  image: `${window.location.origin}${require("../images/logo.svg")}`,
  title: "schungit-original",
  type: "website",
  url: window.location.origin,
};
