import "./scss/index.scss";

import classNames from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";

import { I18n, Trans } from "@lingui/react";

import {
    Button,
    Loader,
    OverlayContext, OverlayTheme,
    OverlayType,
    ProductsFeatured
} from "../../components";
import { TypedMainMenuQuery } from "../../components/MainMenu/queries";

import {generateCategoryUrl, generatePageUrl, maybe} from "../../core/utils";

import {HOME_STATIC_PAGES} from "../../core/config";

import {
  ProductsList_categories,
  ProductsList_page,
  ProductsList_shop,
  ProductsList_shop_homepageCollection_backgroundImage
} from "./types/ProductsList";

import { structuredData } from "../../core/SEO/Homepage/structuredData";

import noPhotoImg from "../../images/no-photo.svg";

const Page: React.FC<{
  loading: boolean;
  categories: ProductsList_categories;
  staticPages: ProductsList_page[];
  backgroundImage: ProductsList_shop_homepageCollection_backgroundImage;
  shop: ProductsList_shop;
}> = ({ loading, categories, staticPages, backgroundImage, shop }) => (
  <>
    <script className="structured-data-list" type="application/ld+json">
      {structuredData(shop)}
    </script>
    <Link to={generateCategoryUrl(categories.edges[0].node.id, categories.edges[0].node.name)} >
      <div
        className="home-page__hero"
        style={
          backgroundImage
            ? { backgroundImage: `url(${backgroundImage.url})` }
            : null
        }
      >
        <div className="home-page__hero-text">
          <div>
            <span className="home-page__hero__title">
              <h1>Angebote</h1>
            </span>
          </div>
          <div>
            <span className="home-page__hero__title">
              <h1>Bis zu 40% sparen</h1>
            </span>
          </div>
        </div>
        <div className="home-page__hero-action">
          {loading && !categories ? (
            <Loader />
          ) : (
            <OverlayContext.Consumer>
              {overlayContext => (
                <TypedMainMenuQuery renderOnError displayLoader={false}>
                  {({ data }) => (
                    <Button
                      onClick={event => {
                        event.preventDefault();
                        overlayContext.show(
                          OverlayType.sideNav,
                          OverlayTheme.left,
                          { data: maybe(() => data.shop.navigation.main.items, []) }
                        )
                      }}
                    >
                      <Trans id="All categories"/>
                    </Button>
                  )}
                </TypedMainMenuQuery>
              )}
            </OverlayContext.Consumer>
          )}
        </div>
      </div>
    </Link>
    <I18n>
        {({ i18n }) => (<ProductsFeatured title={i18n._('Featured')} />)}
    </I18n>
    <div className="home-page__categories">
      <div className="container">
        <div className="home-page__categories__list">
          {staticPages.map((page, index) => (
            <div key={page.id}>
              <Link
                to={generatePageUrl(page.slug)}
                key={page.id}
              >
                <div
                  className={classNames("home-page__categories__list__image", {
                    "home-page__categories__list__image--no-photo": !HOME_STATIC_PAGES[index],
                  })}
                  style={{
                    backgroundImage: `url(${
                      HOME_STATIC_PAGES[index]
                        ? HOME_STATIC_PAGES[index].image
                        : noPhotoImg
                    })`,
                  }}
                />
                <h3>{page.title}</h3>
              </Link>
            </div>
          ))}
        </div>
        <div>
          <hr className="seo-separator" />
          <div className="seo-description">
              <h1 className="iven">Schungit kaufen- der schwarze Stein mit heilender Wirkung</h1>
              <p className="iven">Schungit soll heilen, vitalisieren, stärken, Wasser reinigen und mineralisieren, vor
                negativer Strahlung schützen und für eine Wohlfühlatmosphäre um sich herum sorgen. Dank seiner
                Seltenheit und seines ungewöhnlichen Aussehens ist Schungit ideal als Körper- oder Raumschmuck
                geeignet.</p>

              <h2 className="iven">Wieso sollten Sie Schungit kaufen?</h2>
              <p className="iven">Schungit hat eine besondere Fähigkeit, den menschlichen Organismus vor negativen
                Strahlen zu schützen und ihm nur positive Energien zuzuführen. Die Wirkung von Schungit, lässt sich in
                vielen Bereichen erfolgreich sehen. Erkrankungen wie Stress, Asthma, Allergien oder auch Arthritis
                lassen sich mit Schungit heilen oder lindern. Wenn Sie <strong>Schungit kaufen</strong>, erhalten Sie
                ein zu 100% natürliches und reines Naturprodukt, welches Sie vor Elektrostrahlen und andere Einflüsse
                schützt.</p>

              <h2 className="iven">Schungit Pulver - Nährstoffe für viele Einsatzgebiete</h2>
              <p className="iven">Kaufen Sie Schungit als Pulver, so lassen sich ganz einfach Cremes und Masken
                herstellen. Spülen Sie Ihre Haare mit Schungit Wasser, stärken Sie Ihr Haar und reduzieren
                Haarausfall. Und sogar für Ihre Pflanzen ist Schungit geeignet. Wenn Sie Pulver oder Splitt kaufen und
                davon etwas auf den Boden geben, können Bäume und Blumen so schön blühen wie nie zuvor.</p>

              <h2 className="iven">Was bieten wir Ihnen in unserem Shop?</h2>
              <p className="iven">Kaufen Sie Schungit in unserem Shop. Wir bieten Ihnen eine interessante und breite
                Palette der gängigsten Schungit Produkte. Sie finden bei uns zum Beispiel 100% reines Mumijo, welches
                Sie für verschiedene Erkrankungen einsetzen können. Aus einer schönen Auswahl an Ketten, Armbändern
                und Anhängern, die aus zum Beispiel Edel Schungit und aus Schungit Steinen hergestellt sind, finden
                bestimmt auch Sie das passende Schungit Schmuckstück. Bei uns finden Sie auch Schungit Pyramiden und
                Telefonplättchen, die Sie ganz einfach an Ihr Smartphone anbringen können und somit einen optimalen
                Strahlenschutz erhalten.</p>

              <h2 className="iven">Haben Sie Fragen? Zögern Sie nicht und sprechen uns an!</h2>
              <p className="iven">Sollten Sie beim Kauf von Schungit unsicher sein, welches Produkt am besten zu Ihnen
                passt, zögern Sie nicht uns zu fragen. Wenn Sie in unserem Shop <strong>Schungit kaufen</strong>,
                erhalten Sie Schungit Produkte in einer Top Qualität. 35.000 zufriedene Kunden sprechen für sich.
                Lesen Sie hier auch gerne die zahlreichen Kundenrückmeldungen auf unserer Seite.</p>

              <h2 className="iven">Tun Sie sich und Ihrer Gesundheit etwas Gutes!</h2>
              <p className="iven">Entscheiden Sie sich noch heute für eines unserer Schungit Produkte und tun Sie damit
                sich und Ihrem Körper etwas Gutes. Sie werden schnell merken, welch positive Energien von Schungit
                ausgehen. Kaufen Sie Schungit und Sie werden auf natürliche Weise geschützt.</p>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default Page;
